<template>
  <h1></h1>
</template>

<script>
import axios from "@/libraries/axios";
import { setUserToken } from "@/libraries/axios"
export default {
  name: 'GetUserToken',
  data () {
    return {
      userToken: '',
      host: ''
    }
  },
  mounted () {
    this.getIncomingData()
  },
  methods: {
    getIncomingData() {
      const uri = window.location.search.substring(1)
      const params = new URLSearchParams(uri)
      this.userToken = params.get('ut')

      this.$store.commit('setUserToken', this.userToken)
      this.host = params.get('host')
      this.authUser()
    },
    authUser() {
      const token = localStorage.getItem('userToken')
      this.$store.dispatch('auth/getUserByToken', {
        token: token,
      })
        .then((response) => {
          const user = response.data.data.user
          localStorage.setItem('user', JSON.stringify(user))
          setUserToken(axios, token)
          axios.defaults.headers.get['usertoken'] = token
          axios.defaults.headers.post['usertoken'] = token
          axios.defaults.headers.put['usertoken'] = token
          axios.defaults.headers.delete['usertoken'] = token
          this.$store.commit('setUser', user)
          this.$router.push('/')
        })
    }
  }
}
</script>

<style scoped>

</style>
